import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// components
import Layout from "../../layout/Layout";
//pion library
import { PageHeader } from "@excoleadershipui/pionweblib";

//material ui
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import { Link } from "react-router-dom";
import { format } from "date-fns";

//redux-toolkit
import { useDispatch, useSelector } from "react-redux";
import {
  getSelfLinks,
  getAssessmentStakeholder,
} from "../../features/dashboard/dashBoardSlice";
import { isLoggedIn } from "../../features/auth/authSlice";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "arial",
  },

  // subRoot: {
  //   width: "100%",
  //   maxWidth: 600,
  //   bgcolor: "background.paper",
  //   position: "relative",
  //   overflow: "auto",
  //   maxHeight: 400,
  //   "& ul": { padding: 0 },
  // },
});

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { selfLinkData, stakeHolderData } = useSelector((props) => ({
    ...props.dashBoard,
  }));

  const { loggedIn } = useSelector((props) => ({
    ...props.auth,
  }));

  useEffect(() => {
    dispatch(getSelfLinks());
    dispatch(getAssessmentStakeholder());
    dispatch(isLoggedIn());
  }, []);

  useEffect(() => {
    return loggedIn === false && navigate("/");
  }, [loggedIn]);

  const location = useLocation();

  useEffect(() => {
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(0);
    };
    // console.log("log comming in dashboard")
    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener("popstate", function (event) {
    // window.history.pushState(null, document.title, window.location.href);
    // });
  }, []);

  const splitFunc = (data) => {
    let date = data;
    date = date?.substring(0, 10).split("-");
    date = date[1] + "-" + date[2] + "-" + date[0];
    return date;
  };

  return (
    <Layout>
      <Stack className={classes.root}>
        <Stack sx={{ marginTop: "1rem" }}>
          <PageHeader variant="pageHeader">
            Welcome to The ExCo Leadership Inventory Assessment
          </PageHeader>
        </Stack>

        {selfLinkData.length > 0 || stakeHolderData.length > 0 ? (
          <List
            sx={{
              width: "100%",
              maxWidth: 800,
              bgcolor: "background.paper",
              position: "relative",
              overflow: "auto",
              maxHeight: 400,
              "& ul": { padding: 0 },
            }}
            subheader={<li />}
          >
            {selfLinkData.length > 0 && (
              <li>
                <ul>
                  <ListSubheader sx={{ color: "#000000", fontSize: "1rem" }}>
                    Assess Myself
                  </ListSubheader>

                  {selfLinkData.length > 0 &&
                    selfLinkData.map((data) => {
                      return (
                        <ListItem>
                          <Link
                            style={{ fontSize: "0.8rem" }}
                            to="/assessment/survey_start"
                            state={{
                              from: data?.id,
                              fromOne: "Self",
                              client: `${data?.assessmentCustomerUser?.assessmentCustomerUserDetail?.firstName}
                              ${data?.assessmentCustomerUser?.assessmentCustomerUserDetail?.lastName}
                              `,
                              
                              company:
                                data?.assessmentCustomerUser?.assessmentCustomer
                                  ?.customerName,
                            }}
                          >
                            {data?.assessmentTemplate?.assessmentTemplateDisplayName
                            }
                            <span style={{ display: "block" }}>
                              Deadline for Feedback:{" "}
                              {data?.cutOffDate
                                ? splitFunc(data?.cutOffDate)
                                : ""}
                            </span>
                          </Link>
                        </ListItem>
                      );
                    })}
                </ul>
              </li>
            )}

            {stakeHolderData.length > 0 && (
              <li>
                <ul>
                  <ListSubheader sx={{ color: "#000000", fontSize: "1rem" }}>
                    Provide Feedback
                  </ListSubheader>
                  {stakeHolderData.length > 0 &&
                    stakeHolderData.map((data) => {
                      return (
                        <ListItem>
                          <Link
                            style={{ fontSize: "0.8rem" }}
                            to="/assessment/survey_start"
                            state={{
                              from: data?.assessmentTracking?.id,
                              fromOne: "stakeholder",
                              client: `${data?.assessmentTracking?.assessmentCustomerUser?.assessmentCustomerUserDetail?.firstName}
                          ${data?.assessmentTracking?.assessmentCustomerUser?.assessmentCustomerUserDetail?.lastName}
                          `,
                              company:
                                data?.assessmentTracking?.assessmentCustomerUser
                                  ?.assessmentCustomer?.customerName,
                            }}
                          >
                            {
                              data?.assessmentTracking?.assessmentTemplate
                                ?.assessmentTemplateDisplayName

                            }{" "}
                            for{" "}
                            {`${data?.assessmentTracking?.assessmentCustomerUser?.assessmentCustomerUserDetail?.firstName} ${data?.assessmentTracking?.assessmentCustomerUser?.assessmentCustomerUserDetail?.lastName} `}{" "}
                            <span style={{ display: "block" }}>
                              Deadline for Feedback:{" "}
                              {data?.assessmentTracking?.cutOffDate
                                ? splitFunc(
                                  data?.assessmentTracking?.cutOffDate
                                )
                                : ""}
                            </span>
                          </Link>
                        </ListItem>
                      );
                    })}
                </ul>
              </li>
            )}
          </List>
        ) : (
          <Stack sx={{ fontSize: "1rem", fontWeight: 400 }}>
            You have no active assessments to complete at this time.
          </Stack>
        )}
      </Stack>
    </Layout>
  );
};

export default Dashboard;
