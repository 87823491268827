import React, { useState } from "react";
// material ui
import { makeStyles } from "@mui/styles";
import {
  DataGrid,
  GridRow,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Button, Popover, Typography } from "@mui/material";
// icons
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

// ---------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  dataGridStyle: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

// ----------------------------------------------------------------------------------

const CustomRow = (props) => {
  const classes = useStyles();
  const { className, index, ...other } = props;

  return <GridRow index={index} className={classes.dataGridStyle} {...other} />;
};

//   ------------------------------------------------------------------------------------

const GridToolbarAddButton = (props) => {
  return (
    <Button
      variant="text"
      startIcon={<AddCircleIcon />}
      onClick={() => props.handleClick()}
    >
      New
    </Button>
  );
};

// ------------------------------------------------------------------------------------------

const GridToolbarEditButton = (props) => {
  return (
    <Button
      variant="text"
      startIcon={<EditIcon />}
      onClick={() => props.handleClick()}
    >
      Edit
    </Button>
  );
};

// ---------------------------------------------------------------------------------------------

const GridToolbarDeleteButton = (props) => {
  return (
    <Button
      variant="text"
      startIcon={<DeleteIcon />}
      onClick={() => props.handleClick()}
    >
      Delete
    </Button>
  );
};

// ----------------------------------------------------------------------------------------------

export default function MuiDataGrid(props) {
  const classes = useStyles();
  const [selectedRow, setSelectedRow] = useState([]);

  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const [value, setValue] = React.useState('');

  // const handlePopoverOpen = (event) => {
  //   console.log(event.currentTarget.style.width)
  //   const field = event.currentTarget.dataset.field;
  //   const width = event.width;
  //   const id = event.currentTarget.parentElement.dataset.id;
  //   const row = props.data.rows.find((r) => r.id === id);
    // console.log(Number(row[field].length), Number(event.currentTarget.style.maxWidth.substring(0, event.currentTarget.style.maxWidth.length -2)), Number(row[field].length) > Number(event.currentTarget.style.maxWidth.substring(0, event.currentTarget.style.maxWidth.length -2)))
  //   setValue(row[field]);
  //   setAnchorEl(event.currentTarget);
  // };

  // console.log(anchorEl)
  // const handlePopoverClose = () => {
  //   setAnchorEl(null);
  // };

  // const open = Boolean(anchorEl);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        {props.viewAdd && (
          <GridToolbarAddButton handleClick={props.setShowAdd} />
        )}
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
        <GridToolbarExport />
        {selectedRow.length === 1 && props?.setShowEdit && (
          <GridToolbarEditButton handleClick={props.setShowEdit} />
        )}
        {selectedRow.length === 1 && props.setShowDelete && (
          <GridToolbarDeleteButton handleClick={props.setShowDelete} />
        )}
      </GridToolbarContainer>
    );
  };

  return (
    <div
      style={{
        height: props.height ? props.height : 500,
        width: "100%",
        "&.superAppTheme": {
          "&:nth-of-type(odd)": {
            bgcolor: (theme) => theme.palette.primary.light,
          },
        },
      }}
    >
      <DataGrid
        {...props.data}
        components={{
          Toolbar: CustomToolbar,
          Row: CustomRow,
          
        }}
        // componentsProps={{
        //   cell: {
        //     onMouseEnter: handlePopoverOpen,
        //     onMouseLeave: handlePopoverClose,
        //   },
        // }}
        onSelectionModelChange={(ids) => {
          const selectedRowData =
            props.data.rows.length > 0 &&
            props.data.rows.filter(
              (row) => ids.length > 0 && row.id === ids[0]
            );
          props.select(selectedRowData);
          setSelectedRow(selectedRowData);
        }}
      />
      {/* <Popover
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{value}</Typography>
      </Popover> */}
    </div>
  );
}
