import logo from './logo.svg';
import { CssBaseline } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import ExcoLogin from './pages/signin/SignIn';
import {ConfirmAccount,ForgetPassword,ForgotPassReset} from "./components"
import {  
  SideBarMenu, 
  Preview ,
  // HrEngagements, 
  DashboardExco,
  ShortResponse,
  StartPage,
  FinishPage,
  // Assesment,
  // Coaching,
  Rating,
  // ClientReports,
  MyProfile,
  // HrDash, HrExec,HrAsses,HrRatin,HrReports,HrCoaching,
  // ManagerDashboard,ManagerCoachingStatus,ManagerAssesment,ManagerRatings,ManagerReports,
  PageNotFound,
} from "./pages";





// material ui

function App() {
  return (
  <>
  <CssBaseline />
   <Router>
   <Routes>
   <Route path = "/"   element={<ExcoLogin/>} />
   <Route path="/activation" element={<ConfirmAccount />} />
   <Route path="forgot-password" element={<ForgetPassword />} />
   <Route path="forgot" element={<ForgotPassReset />} />


  
   <Route path="assessment/survey_start" element={<StartPage/>} />
   <Route path="assessment/survey_finish" element={<FinishPage/>} />
   <Route path="assessment/short_response" element={<ShortResponse/>} />
   <Route path="assessment/preview" element={<Preview />} />
   <Route path="assessment/rating" element={<Rating/>} />

   <Route path="/exco" element={<SideBarMenu />}>
   <Route path="dashboard" element={<DashboardExco />} />
  
   <Route path="my-profile" element={<MyProfile />} />
   
 

    {/* <Route path="assesment" element={<Assesment />} />
    <Route path="coaching_focus" element={<Coaching/>} />
   
    <Route path="client/reports" element={<ClientReports/>} />
    <Route path="hr/dashboard" element={<HrDash/>} />
    <Route path="hr/engagements" element={<HrEngagements/>} />
    <Route path="hr/executive" element={<HrExec/>} />
    <Route path="hr/assesment" element={<HrAsses/>} />
    <Route path="hr/reports" element={<HrReports/>} />
    <Route path="hr/rating" element={<HrRatin/>} />
    <Route path="hr/coaching_focus" element={<HrCoaching/>} />
  
    <Route path="manager/dashboard" element={<ManagerDashboard />} />
    <Route path="manager/assesment" element={<ManagerAssesment />} />
    <Route path="manager/coaching_focus" element={<ManagerCoachingStatus />} />
    <Route path="manager/ratings" element={<ManagerRatings />} />
    <Route path="manager/reports" element={<ManagerReports />} /> */}
   </Route>
   <Route path="*" element={<PageNotFound />} />
   </Routes> 
   </Router>
  </>
  );
}

export default App;
