import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
// material ui
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Collapse from "@mui/material/Collapse";
import { makeStyles } from "@mui/styles";
// icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SupportIcon from "@mui/icons-material/Support";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AttractionsIcon from "@mui/icons-material/Attractions";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import SchemaIcon from "@mui/icons-material/Schema";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AssistantIcon from "@mui/icons-material/Assistant";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import AssessmentIcon from "@mui/icons-material/Assessment";

import AssignmentIcon from "@mui/icons-material/Assignment";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

// components
import { MuiTooltip } from "../../components";

// --------------------------------------------------------------------------------

import { useSelector, useDispatch } from "react-redux";
import { getAllRoles } from "../../features/auth/authSlice";

const useStyles = makeStyles((theme) => ({
  listButton: {
    "& .MuiListItemText-root": {
      color: "white",
    },
    "& .drawerIcon": {
      color: "white",
    },
    "&:hover .drawerIcon": {
      color: theme.palette.primary.light,
    },
    "&.Mui-selected": {
      borderLeft: "0.25rem solid #00527d",
      paddingLeft: "0.75rem",
      backgroundColor: "#ffffff !important",
    },
    "&.Mui-selected .MuiListItemText-root": {
      color: "#000000 !important",
    },
    "&.Mui-selected .drawerIcon": {
      color: theme.palette.primary.main,
    },
    "&.Mui-selected .MuiSvgIcon-root": {
      color: theme.palette.black.main,
    },
  },
  collapseListButton: {
    paddingLeft: "2rem !important",
    "& .MuiListItemText-root": {
      color: "white",
    },
    "& .drawerIcon": {
      color: "white",
    },
    "&:hover .drawerIcon": {
      color: theme.palette.primary.light,
    },
    "&.Mui-selected": {
      borderLeft: "0.25rem solid #00527d",
      paddingLeft: "1.75rem !important",
      backgroundColor: "#ffffff !important",
    },
    "&.Mui-selected .MuiListItemText-root": {
      color: theme.palette.black.main,
    },
    "&.Mui-selected .drawerIcon": {
      color: theme.palette.primary.main,
    },
  },
  MuiLinks: {
    textDecoration: "none",
    color: "inherit",
  },
  listText: {
    "& span": {
      fontFamily: "Arial",
      fontSize: "1.25rem",
      fontWeight: 500,
      lineHeight: "normal",
      letterSpacing: "0em",
    },
  },
}));
// -------------------------------------------------------------------------------------

export const MainListItems = ({
  show,
  roleData,
  particularRole,
  rolesFinalData,
}) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [custOpen, setCustOpen] = React.useState(false);
  const [isClient, setIsClient] = React.useState(false);
  const [isHr, setIsHr] = React.useState(true);
  const [role, setRole] = React.useState(particularRole);
  const [isManager, setIsManager] = React.useState(false);

  console.log(rolesFinalData);

  // const[roleArr,setRoleArr] = React.useState([])

  const classes = useStyles(custOpen);

  const dispatch = useDispatch();
 

  const { getAllRolesData, rolesData, localStoreRoleData } = useSelector(
    (state) => ({
      ...state.auth,
    })
  );

  // handleListItemClick function to set the selected value
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  // handleCustListClick function to expand the customer menu
  const handleCustListClick = () => {
    setCustOpen(!custOpen);
  };

  //   useEffect(()=>{
  //     dispatch(getAllRoles())
  // },[])

  // const roleArr = [
  //   {
  //     id: 1,
  //     role: "HR",
  //   },
  //   {
  //     id: 2,
  //     role: "MANAGER",
  //   },
  //   {
  //     id: 3,
  //     role: "CLIENT",
  //   },
  // ];
  // roleArr.length > 0 &&
  //   roleArr.forEach((data) => {
  //     console.log(data);
  //     roleArr.push(data.role);
  //   });

  // let resultD =
  //   Object.keys(rolesFinalData).length > 0 &&
  //   rolesFinalData.getExcoCustomerUserRoles.length === 1
  //     ? rolesFinalData.getExcoCustomerUserRoles[0].role
  //     : localStorage.getItem("state");

  const location = useLocation();
  

  useEffect(()=>{
    location.pathname === "/exco/dashboard" && setSelectedIndex(1)
  },[])

  return (
    <React.Fragment>
      <div>
        <MuiTooltip title={show ? "" : "Dashboard"} placement="right">
          <Link to="dashboard" className={classes.MuiLinks}>
            <ListItemButton
              className={classes.listButton}
              selected={selectedIndex === 1}
              onClick={(event) => handleListItemClick(event, 1)}
            >
              <ListItemIcon>
                <DashboardIcon className="drawerIcon" />
              </ListItemIcon>
              {show && (
                <ListItemText
                  primary="Dashboard"
                  className={classes.listText}
                />
              )}
            </ListItemButton>
          </Link>
        </MuiTooltip>

        {/* <MuiTooltip title={show ? "" : "Survey Start"} placement="right">
        <Link to="/assessment/survey_start" className={classes.MuiLinks}>
          <ListItemButton
            className={classes.listButton}
            selected={selectedIndex === 12}
            onClick={(event) => handleListItemClick(event, 12)}
          >
            <ListItemIcon className="drawerIcon">
              <StarOutlineIcon />
            </ListItemIcon>
            {show && (
              <ListItemText
                primary="Survey Start"
                className={classes.listText}
              />
            )}
          </ListItemButton>
        </Link>
      </MuiTooltip> */}

        {/* <MuiTooltip title={show ? "" : "Outcomes"} placement="right">
        <Link to="/client/rating" className={classes.MuiLinks}>
          <ListItemButton
            className={classes.listButton}
            selected={selectedIndex === 9}
            onClick={(event) => handleListItemClick(event, 9)}
          >
            <ListItemIcon className="drawerIcon">
              <StarOutlineIcon />
            </ListItemIcon>
            {show && (
              <ListItemText
                primary="Outcomes"
                className={classes.listText}
              />
            )}
          </ListItemButton>
        </Link>
      </MuiTooltip> */}

        {/* <MuiTooltip title={show ? "" : "Assessment Preview"} placement="right">
        <Link to="/assessment/preview" className={classes.MuiLinks}>
          <ListItemButton
            className={classes.listButton}
            selected={selectedIndex === 10}
            onClick={(event) => handleListItemClick(event, 10)}
          >
            <ListItemIcon className="drawerIcon">
              <StarOutlineIcon />
            </ListItemIcon>
            {show && (
              <ListItemText
                primary="Preview"
                className={classes.listText}
              />
            )}
          </ListItemButton>
        </Link>
      </MuiTooltip> */}

        {/* <MuiTooltip title={show ? "" : "Assessment Preview"} placement="right">
        <Link to="/assessment/short_response" className={classes.MuiLinks}>
          <ListItemButton
            className={classes.listButton}
            selected={selectedIndex === 11}
            onClick={(event) => handleListItemClick(event, 11)}
          >
            <ListItemIcon className="drawerIcon">
              <StarOutlineIcon />
            </ListItemIcon>
            {show && (
              <ListItemText
                primary="Short Response"
                className={classes.listText}
              />
            )}
          </ListItemButton>
        </Link>
      </MuiTooltip> */}

        {/* <MuiTooltip title={show ? "" : "Survey Finish"} placement="right">
        <Link to="assessment/survey_finish" className={classes.MuiLinks}>
          <ListItemButton
            className={classes.listButton}
            selected={selectedIndex === 13}
            onClick={(event) => handleListItemClick(event, 13)}
          >
            <ListItemIcon className="drawerIcon">
              <StarOutlineIcon />
            </ListItemIcon>
            {show && (
              <ListItemText
                primary="Survey Finish"
                className={classes.listText}
              />
            )}
          </ListItemButton>
        </Link>
      </MuiTooltip> */}
      </div>
    </React.Fragment>
  );
};
