import React from "react";
// material ui
import { Container, Box } from "@mui/material";
// image
import BgImage from "../assets/images/X-light-gray.png";

// -------------------------------------------------------------------------

const Layout = ({isSidebarNotShow, children }) => {
  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Container
        maxWidth="lg"
        component="div"
        sx={{
          mt: isSidebarNotShow ? 3 : 10,
          mb: 4,
          pt: "1rem !important",
          pr: "3rem !important",
          height: "80vh",
          background: `url(${BgImage})`,
          backgroundSize: "contain",
          backgroundPosition: "right bottom",
          backgroundRepeat: "no-repeat",
        }}
      >
        {children}
      </Container>
    </Box>
  );
};

export default Layout;