import React, { useEffect } from "react";

// components
import Layout from "../layout/Layout";

import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

//redux-toolkit

import { useDispatch, useSelector } from "react-redux";
import {
  assessmentTrackingTemplate,
  assessmentTrackingTemplateForClients,
} from "../features/dashboard/dashBoardSlice";
import { isLoggedIn } from "../features/auth/authSlice";

const SurveyStart = (props) => {
  const { assessmentTemplateData } = useSelector((props) => ({
    ...props.dashBoard,
  }));
  const location = useLocation();
  const { state } = location;
  console.log(state);

  localStorage.setItem("clientName", state?.client);
  localStorage.getItem("clientName");
 

  localStorage.setItem("company", state?.company);
  localStorage.getItem("company");

  localStorage.setItem("role", state?.fromOne);
  localStorage.getItem("role");

  let dispatch = useDispatch();

  let isCheckingSelf = localStorage.getItem("role");

  useEffect(() => {
    localStorage.removeItem("pageLocalNumber");
    localStorage.removeItem("progressValueLocal");
    localStorage.removeItem("progressBarValue");

    localStorage.setItem("state", state?.from);
    localStorage.getItem("state");

    if (isCheckingSelf === "Self") {
      dispatch(
        assessmentTrackingTemplateForClients({
          assessmentTrackingId: localStorage.getItem("state"),
        })
      );
    } else if (isCheckingSelf === "stakeholder") {
      dispatch(
        assessmentTrackingTemplate({
          assessmentTrackingId: localStorage.getItem("state"),
        })
      );
    }

    dispatch(isLoggedIn());
  }, []);

  let client = `${state?.client?.trimEnd()}.`;
  let clientFullName = state?.client;

  let firstName = state?.client?.split("\n")[0];
  return (
    <Layout>
      {state?.fromOne === "Self" && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          height="60vh"
          spacing={0}
        >
          <Grid sm={12} md={9} xs={12}>
            <Stack
              sx={{
                textAlign: "center",
                fontSize: "1.75rem",
                fontWeight: 500,
                paddingBottom: "1rem",
              }}
            >
              Welcome to the ExCo Leadership Inventory.
            </Stack>
            <Stack
              sx={{
                textAlign: "center",
                fontSize: "1.25rem",
                fontWeight: 400,
                paddingBottom: "1rem",
                display: "inline-block",
              }}
            >
              Please respond to the following prompts in regards to yourself.
              Your responses will help create a baseline for development against
              the feedback from your most critical stakeholders.
            </Stack>
            <Stack
              sx={{
                textAlign: "center",
                fontSize: "1.25rem",
                fontWeight: 400,
                paddingBottom: "1rem",
                display: "inline-block",
              }}
            >
              You will assess yourself, providing ratings and commentary,
              against dimensions critical to leadership at{" "}
              <span style={{ fontWeight: 500 }}>{state?.company}. </span>
            </Stack>
            <Stack
              sx={{
                textAlign: "center",
                fontSize: "1.25rem",
                fontWeight: 400,
                paddingBottom: "1rem",
              }}
            >
              This survey will take 15-20 minutes to complete.
            </Stack>

            <Stack
              sx={{
                textAlign: "center",
                fontSize: "1.25rem",
                fontWeight: 400,
                paddingBottom: "1rem",
                display: "block",
              }}
            >
              We truly appreciate your insights, time and look forward to
              meeting with you live to discuss your feedback and next steps.
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
                justifyContent: "center",
                paddingBottom: "2rem",
              }}
            >
              <Link style={{ textDecoration: "none" }} to="/assessment/rating">
                <Button variant="contained" color="primary" size="large">
                  Start Assessment
                </Button>
              </Link>
            </Stack>
          </Grid>
        </Grid>
      )}

      {state?.fromOne === "stakeholder" && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          height="60vh"
          spacing={0}
        >
          <Grid sm={12} md={10} xs={12}>
            <Stack
              sx={{
                textAlign: "center",
                fontSize: "1.75rem",
                fontWeight: 500,
                paddingBottom: "1rem",
              }}
            >
              Welcome to the ExCo Leadership Inventory.
            </Stack>
            <Stack
              sx={{
                textAlign: "center",
                fontSize: "1.25rem",
                fontWeight: 400,
                paddingBottom: "1rem",
                display: "block",
              }}
            >
              <span style={{ fontWeight: 500 }}>{clientFullName} </span> is
              seeking your input as a critical stakeholder with valuable
              feedback.
            </Stack>
            <Stack
              sx={{
                textAlign: "center",
                fontSize: "1.25rem",
                fontWeight: 400,
                paddingBottom: "1rem",
                display: "block",
              }}
            >
              You will assess{" "}
              <span style={{ fontWeight: 500 }}>{firstName}, </span> providing
              ratings and commentary, against dimensions critical to leadership
              at <span style={{ fontWeight: 500 }}>{state?.company}. </span>
            </Stack>
            <Stack
              sx={{
                textAlign: "center",
                fontSize: "1.25rem",
                fontWeight: 400,
                paddingBottom: "1rem",
                display: "block",
              }}
            >
              Please respond to the following prompts regarding{" "}
              <span style={{ fontWeight: 500 }}>{client}</span> Your feedback is
              confidential and anonymous and will help create a baseline for
              their development.
            </Stack>
            <Stack
              sx={{
                textAlign: "center",
                fontSize: "1.25rem",
                fontWeight: 400,
                paddingBottom: "1rem",
              }}
            >
              This survey will take 15-20 minutes to complete.
            </Stack>

            <Stack
              sx={{
                textAlign: "center",
                fontSize: "1.25rem",
                fontWeight: 400,
                paddingBottom: "1rem",
                display: "block",
              }}
            >
              We truly appreciate your insights, time and commitment to
              developing leaders with{" "}
              <span style={{ fontWeight: 500 }}>{state?.company}. </span>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
                justifyContent: "center",
                paddingBottom: "2rem",
              }}
            >
              <Link style={{ textDecoration: "none" }} to="/assessment/rating">
                <Button variant="contained" color="primary" size="large">
                  Start Assessment
                </Button>
              </Link>
            </Stack>
          </Grid>
        </Grid>
      )}
    </Layout>
  );
};

export default SurveyStart;
