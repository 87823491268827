import React, { useState } from "react";
// material ui
import { makeStyles } from "@mui/styles";
import {
  DataGrid,
  GridRow,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Button } from "@mui/material";
// icons
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

// ---------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  dataGridStyle: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

// ----------------------------------------------------------------------------------

const CustomRow = (props) => {
  const classes = useStyles();
  const { className, index, ...other } = props;

  return <GridRow index={index} className={classes.dataGridStyle} {...other} />;
};

//   ------------------------------------------------------------------------------------

const GridToolbarAddButton = (props) => {
  return (
    <Button
      variant="text"
      startIcon={<AddCircleIcon />}
      onClick={() => props.handleClick()}
    >
      New
    </Button>
  );
};

// ------------------------------------------------------------------------------------------

const GridToolbarEditButton = (props) => {
  return (
    <Button
      variant="text"
      startIcon={<EditIcon />}
      onClick={() => props.handleClick()}
    >
      Edit
    </Button>
  );
};

// ---------------------------------------------------------------------------------------------

const GridToolbarDeleteButton = (props) => {
  return (
    <Button
      variant="text"
      startIcon={<DeleteIcon />}
      onClick={() => props.handleClick()}
    >
      Delete
    </Button>
  );
};

// ----------------------------------------------------------------------------------------------

export default function MuiDataGrid(props,{handleClick}) {

  const classes = useStyles();
  const [selectedRow, setSelectedRow] = useState([]);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        {/* {props.viewAdd && (
          <GridToolbarAddButton handleClick={props.setShowAdd} />
        )} */}
        {/* <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport /> */}
        {selectedRow.length === 1 && (
          <GridToolbarEditButton handleClick={props.setShowEdit} />
        )}
        {selectedRow.length === 1 && (
          <GridToolbarDeleteButton handleClick={props.setShowDelete} />
        )}
      </GridToolbarContainer>
    );
  };

  console.log(props.data.rows)

  return (
    // <div
    //   style={{
    //     height: props.height ? props.height : 500,
    //     width: "100%",

    //     "&.superAppTheme": {
    //       "&:nth-of-type(odd)": {
    //         bgcolor: (theme) => theme.palette.primary.light,
    //       },
    //     },
    //   }}
    // >
    //   <DataGrid
    //     autoHeight
    //     {...props.data}
    //     components={{
    //       Toolbar: CustomToolbar,

    //       Row: CustomRow,
    //     }}
    //     onSelectionModelChange={(ids) => {
    //       const selectedRowData =
    //         props.data.rows.length > 0 &&
    //         props.data.rows.filter(
    //           (row) => ids.length > 0 && row.id === ids[0]
    //         );
    //        props.select(selectedRowData);
    //        setSelectedRow(selectedRowData);
    //     }}
       
     
     
     
    //   />
    // </div>

    <div
      style={{
       width: "100%",
        "&.superAppTheme": {
          "&:nth-of-type(odd)": {
            bgcolor: (theme) => theme.palette.primary.light,
          },
        },
      }}
    >

        <DataGrid
     autoHeight
        {...props.data}
        components={{
          Toolbar: CustomToolbar,
          Row: CustomRow,
        }}

        onSelectionModelChange={(ids) => {
          const selectedRowData =
            props.data.rows.length > 0 &&
            props.data.rows.filter(
              (row) => ids.length > 0 && row.id === ids[0]
            );
          props.select(selectedRowData);
          setSelectedRow(selectedRowData);
        }}
      />

    </div>
  );
}
