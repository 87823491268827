import { ThemeProvider } from "@mui/material";
import { theme } from "../theme/Theme";
export { default as MyProfile } from "./my_profile/MyProfile";
export { default as Preview } from "./preview/previewAssessment";
export { default as ShortResponse } from "./Ratings/short response";
import SideBar from "./sidebar/Sidebar";
import Dashboard from "./dashboard/Dashboard";
export { default as StartPage } from "./surveyStart";
export { default as FinishPage } from "./surveyFinish";
import ClientRating from "./Ratings/ratings";
export { default as PageNotFound } from "./page_not_found/PageNotFound";


export function SideBarMenu() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <SideBar />
      </ThemeProvider>
    </div>
  );
}

export function DashboardExco() {
  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <Dashboard />
      </ThemeProvider>
    </div>
  );
}

export function Rating() {
  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <ClientRating />
      </ThemeProvider>
    </div>
  );
}
