import React, { useEffect } from "react";

// components

//mui
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

//redux-toolkit
import { useDispatch, useSelector } from "react-redux";

import {
  getSelfLinks,
  getAssessmentStakeholder,
  finishAssessmentForClients,
} from "../features/dashboard/dashBoardSlice";

const SurveyFinish = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSurveyFinish = () => {
    dispatch(finishAssessmentForClients({ id: localStorage.getItem("state") }));
    dispatch(getSelfLinks());
    dispatch(getAssessmentStakeholder());
    navigate("/exco/dashboard");
  };

  useEffect(() => {
    setTimeout(() => {
      navigate("/exco/dashboard");
    }, 5000);
  }, []);
  let company = localStorage.getItem("company");
  let client = localStorage.getItem("clientName");
  let isRole = localStorage.getItem("role");

  return (
    <Grid
      container
      sm={12}
      md={12}
      xs={12}
      direction="row"
      justifyContent="center"
      alignItems="center"
      height="90vh"
      spacing={0}
    >
      {isRole === "Self" && (
        <Grid sm={12} md={9} xs={12}>
          <Stack
            sx={{
              textAlign: "center",
              fontSize: "1.75rem",
              fontWeight: 500,
              paddingBottom: "1rem",
            }}
          >
            Thank you for taking the time to complete this survey. Your
            responses have been successfully shared.
          </Stack>

          {/* <Stack sx={{ textAlign: "center", fontSize: "1.25rem", fontWeight: 400,paddingBottom:"1rem",display:"block", }}>
          We value the information you have provided and your responses will
          contribute to providing <span style={{fontWeight:500}}>{client}</span> with actionable feedback to accelerate
          their leadership impact.
        </Stack> */}
          <Stack
            sx={{
              textAlign: "center",
              fontSize: "1.25rem",
              fontWeight: 400,
              paddingBottom: "1rem",
              display: "block",
            }}
          >
            We appreciate your insights, time and look forward to meeting with
            you live to discuss your feedback and next steps.
          </Stack>
          {/* <Stack sx={{ textAlign: "center", fontSize: "1.25rem", fontWeight: 400,paddingBottom:"1rem",display:"block" }}>
        We truly appreciate your insights, time and commitment to developing leaders within <span style={{fontWeight:500}}>{company}. </span>  

        </Stack> */}

          <Stack
            sx={{
              textAlign: "center",
              fontSize: "1.25rem",
              fontWeight: 400,
              paddingBottom: "1rem",
              display: "block",
            }}
          >
            Please reach out to us at{" "}
            <a href="#">support.assessments@excoleadership.com</a> with any
            questions.
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            {/* <Link style={{ textDecoration: "none" }} to="/exco/dashboard"> */}
            <Button
              onClick={handleSurveyFinish}
              variant="contained"
              color="primary"
              size="large"
            >
              Home
            </Button>
            {/* </Link> */}
          </Stack>
        </Grid>
      )}

      {isRole === "stakeholder" && (
        <Grid sm={12} md={9} xs={12}>
          <Stack
            sx={{
              textAlign: "center",
              fontSize: "1.75rem",
              fontWeight: 500,
              paddingBottom: "1rem",
            }}
          >
            Thank you for taking the time to complete this survey. Your
            responses have been successfully shared.
          </Stack>

          <Stack
            sx={{
              textAlign: "center",
              fontSize: "1.25rem",
              fontWeight: 400,
              paddingBottom: "1rem",
              display: "block",
            }}
          >
            We value the information you have provided and your responses will
            contribute to providing{" "}
            <span style={{ fontWeight: 500 }}>{client}</span> with actionable
            feedback to accelerate their leadership impact.
          </Stack>

          <Stack
            sx={{
              textAlign: "center",
              fontSize: "1.25rem",
              fontWeight: 400,
              paddingBottom: "1rem",
              display: "block",
            }}
          >
            We truly appreciate your insights, time and commitment to developing
            leaders within <span style={{ fontWeight: 500 }}>{company}. </span>
          </Stack>

          <Stack
            sx={{
              textAlign: "center",
              fontSize: "1.25rem",
              fontWeight: 400,
              paddingBottom: "1rem",
              display: "block",
            }}
          >
            Please reach out to us at{" "}
            <a href="#">support.assessments@excoleadership.com</a> with any
            questions.
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            {/* <Link style={{ textDecoration: "none" }} to="/exco/dashboard"> */}
            <Button
              onClick={handleSurveyFinish}
              variant="contained"
              color="primary"
              size="large"
            >
              Home
            </Button>
            {/* </Link> */}
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export default SurveyFinish;
