import { ASSESSMENT_CUSTOMER_BASE_URL,MICRO_SITE_URL,MICRO_SITE_MOCK_URL, ROLE_URL, BASE_HR_URL } from "./config";

// ----------------------------------------------------------
// login authentication api's
export const EMAIL_VERIFY = `${ASSESSMENT_CUSTOMER_BASE_URL}/v1/api/assessment-customer-user/verify/login`;
export const PASS_PASSWORD_ACCOUNT = `${ASSESSMENT_CUSTOMER_BASE_URL}/v1/api/assessment-customer-user/login`
export const CHANGE_PASSWORD_ACCOUNT_API = `${ASSESSMENT_CUSTOMER_BASE_URL}/v1/api/assessment-customer-user/change-password-login`
export const LOGIN_ACCOUNT = `${ASSESSMENT_CUSTOMER_BASE_URL}/v1/api/assessment-customer-user/verify/login-code`;

export const ACTIVATE_ACCOUNT = `${ASSESSMENT_CUSTOMER_BASE_URL}/v1/api/assessment-customer-user/confirm-account`;
export const CONFIRM_EMAIL_ACCOUNT = `${ASSESSMENT_CUSTOMER_BASE_URL}/v1/api/assessment-customer-user/verify/login-code`
export const LOGOUT_ACCOUNT = `${ASSESSMENT_CUSTOMER_BASE_URL}/v1/api/assessment-customer-user/logout`;
export const CURRENT_USER_DETAILS_API = `${ASSESSMENT_CUSTOMER_BASE_URL}/v1/api/assessment-customer-user/current-user`;
export const UPLOAD_PROFILE_PIC = `${ASSESSMENT_CUSTOMER_BASE_URL}/v1/api/assessment-customer-user/profile-pic`
export const IS_LOGGED_IN = `${ASSESSMENT_CUSTOMER_BASE_URL}/v1/api/assessment-customer-user/is-assessment-customer-user-logged-in`;




export const CREATE_ACCOUNT = `${ASSESSMENT_CUSTOMER_BASE_URL}/authentication/create`;
export const GET_ACCOUNTS = `${ASSESSMENT_CUSTOMER_BASE_URL}/authentication/all/exco-admin`;


export const LOGGED_IN_ROLES = `${ASSESSMENT_CUSTOMER_BASE_URL}/authentication/role`;

export const FORGOT_PASSWORD_EMAIL_VERIFY = `${ASSESSMENT_CUSTOMER_BASE_URL}/v1/api/assessment-customer-user/forgot-password`
export const FORGOT_PASSWORD_RESET = `${ASSESSMENT_CUSTOMER_BASE_URL}/v1/api/assessment-customer-user/forgot-password-confirm`

export const FORGOT_PASSWORD_CODE_SET = `${ASSESSMENT_CUSTOMER_BASE_URL}/v1/api/assessment-customer-user/send-forgot-secret`

// dashboard api

export const ASSESSMENT_TRACKING_SELF = `${ASSESSMENT_CUSTOMER_BASE_URL}/v1/api/assessment-tracking/self`
export const ASSESSMENT_TRACKING_STAKEHOLDER = `${ASSESSMENT_CUSTOMER_BASE_URL}/v1/api/assessment-tracking/stakeholder`
export const  ASSESSMENT_TRACKING_TRACK_ONE =  `${ASSESSMENT_CUSTOMER_BASE_URL}/v1/api/assessment-tracking/get-template-by-tracking-id-two`
export const  ASSESSMENT_TRACKING_TRACK_TWO =  `${ASSESSMENT_CUSTOMER_BASE_URL}/v1/api/assessment-tracking/get-template-by-tracking-id-two-self`
export const  FINISH_ASSESSMENT_API =  `${ASSESSMENT_CUSTOMER_BASE_URL}/v1/api/assessment-tracking/complete-assessment`

//dynamic questions
export const  ADD_TRACKING_QUESTIONS =  `${ASSESSMENT_CUSTOMER_BASE_URL}/v1/api/response-tracking/create-or-update`




































//customer creation

export const ADD_CUSTOMER_CREATION = `${ASSESSMENT_CUSTOMER_BASE_URL}/customer`;

export const GET_CUSTOMERS = `${ASSESSMENT_CUSTOMER_BASE_URL}/customer`;

//mentor creation

export const ADD_MENTOR_CREATION = `${ASSESSMENT_CUSTOMER_BASE_URL}/mentor-onboarding`;

export const GET_MENTORS = `${ASSESSMENT_CUSTOMER_BASE_URL}/mentor-onboarding`;

//microsite creation

export const ADD_MICROSITE_CREATION = `${MICRO_SITE_URL}/microsite`;

export const GET_MICROSITE_API = `${MICRO_SITE_URL}/microsite`;

export const GET_ALL_CUSTOMER_API = `${MICRO_SITE_URL}/customer`

//get all roles

export const GET_ALL_ROLES = `${ROLE_URL}/customer/customer-client-roles`;


//dashbpard detail

export const GET_ENGAGEMENT_DETAIL = `${ASSESSMENT_CUSTOMER_BASE_URL}/client-engagement`
export const GET_CURRENT_MENTOR_NAME = `${ASSESSMENT_CUSTOMER_BASE_URL}/client-mentor-mapping/client-mentor`

export const GET_CLIENT_ENGAGEMENT_STAGE = `${ASSESSMENT_CUSTOMER_BASE_URL}/client-engagement/latest`

//assesment

export const GET_ASSESMENT_STRENGTH = `${ASSESSMENT_CUSTOMER_BASE_URL}/assessment/strength/client`
export const APPROVE_ASSESSMENT_STRENGTH = `${ASSESSMENT_CUSTOMER_BASE_URL}/assessment/approve-strength`

export const GET_ASSESMENT_IMPROVEMENT = `${ASSESSMENT_CUSTOMER_BASE_URL}/assessment/area-of-improvement/client`
export const APPROVE_ASSESSMENT_IMPROVEMENT = `${ASSESSMENT_CUSTOMER_BASE_URL}/assessment/approve-area-of-improvement`

export const GET_CLIENT_CORE_VALUES = `${ASSESSMENT_CUSTOMER_BASE_URL}/leadership-core-value/client`;
export const APPROVE_LEADERSHIP_CORE_VALUES = `${ASSESSMENT_CUSTOMER_BASE_URL}/leadership-core-value/approve-leadership-core-value`

export const GET_APPROVE_BUTTON_HR = `${ASSESSMENT_CUSTOMER_BASE_URL}/assessment/approve-button-name-hr`
export const GET_APPROVE_BUTTON_MANAGER = `${ASSESSMENT_CUSTOMER_BASE_URL}/assessment/approve-button-name-manager`

export const UPDATE_PRIVATE_LEADERSHIP_IMPROVEMENT = `${ASSESSMENT_CUSTOMER_BASE_URL}/assessment/update-to-private-area-of-improvement`
export const UPDATE_PRIVATE_LEADERSHIP_STRENGTH = `${ASSESSMENT_CUSTOMER_BASE_URL}/assessment/update-to-private-strength`
export const UPDATE_PRIVATE_CORE_VALUES = `${ASSESSMENT_CUSTOMER_BASE_URL}/leadership-core-value/update-to-private-leadership-core-value`
export const UPDATE_PRIVATE_LEADERSHIP_COACHING_FOCUS = `${ASSESSMENT_CUSTOMER_BASE_URL}/coaching-focus/update-to-private-coaching-focus`
export const SHARE_WITH_HR = `${ASSESSMENT_CUSTOMER_BASE_URL}/assessment/submit-assessment-hr`
export const SHARE_WITH_MANAGER = `${ASSESSMENT_CUSTOMER_BASE_URL}/assessment/submit-assessment-manager`







//focus
export const GET_CLIENT_IMPROVEMENT = `${ASSESSMENT_CUSTOMER_BASE_URL}/coaching-focus/client`
export const APPROVE_CLIENT_IMPROVEMENT = `${ASSESSMENT_CUSTOMER_BASE_URL}/coaching-focus/approve-coaching-focus`
export const COACHING_SHARE_WITH_HR = `${ASSESSMENT_CUSTOMER_BASE_URL}/coaching-focus/submit-coaching-focus-hr`
export const COACHING_SHARE_WITH_MANAGER = `${ASSESSMENT_CUSTOMER_BASE_URL}/coaching-focus/submit-coaching-focus-manager`


export const GET_APPROVE_COACHING__BUTTON_HR = `${ASSESSMENT_CUSTOMER_BASE_URL}/coaching-focus/approve-button-name-hr`
export const GET_APPROVE_COACHING_BUTTON_MANAGER = `${ASSESSMENT_CUSTOMER_BASE_URL}/coaching-focus/approve-button-name-manager`

//reports

export const GET_REPORTS_AREA_FOCUS = `${MICRO_SITE_MOCK_URL}/area-of-focus/3dcbb765-20af-4a4a-9127-e84c81576faa`
export const GET_REPORTS_ENGAGEMENT_STATUS = `${MICRO_SITE_MOCK_URL}/engagement-notes/3dcbb765-20af-4a4a-9127-e84c81576faa`

// hr
//nomination 

export const ADD_NOMINATION_CREATION_HR = `${ASSESSMENT_CUSTOMER_BASE_URL}/nomination`;
export const DELETE_NOMINATION_CREATION_HR = `${ASSESSMENT_CUSTOMER_BASE_URL}/nomination`;

export const GET_NOMINATION_HR = `${ASSESSMENT_CUSTOMER_BASE_URL}/nomination`;


//hr Leadership profile

export const GET_ALL_CLIENTS_HR = `${ASSESSMENT_CUSTOMER_BASE_URL}/client-reporting-mapping/get-all-client-for-hr`;
export const GET_HR_CLIENT_STRENGTS_BY_ID = `${ASSESSMENT_CUSTOMER_BASE_URL}/assessment/all-strength-for-hr`;
export const GET_HR_CLIENT_IMPROVEMENT_BY_ID = `${ASSESSMENT_CUSTOMER_BASE_URL}/assessment/all-area-of-improvement-for-hr`;
export const GET_HR_CORE_VALUES_BY_ID = `${ASSESSMENT_CUSTOMER_BASE_URL}/leadership-core-value/all-leadership-core-value-for-hr`;


//hr coaching focus

export const GET_HR_COACHING_FOCUS = `${ASSESSMENT_CUSTOMER_BASE_URL}/coaching-focus/all-coaching-focus-for-hr`;

//hr dashboard latest

export const GET_Latest_CLIENT_HR = `${ASSESSMENT_CUSTOMER_BASE_URL}/authentication/hr/top-five-client`;
export const GET_ACTIVE_PAST_ENGAGEMENT = `${ASSESSMENT_CUSTOMER_BASE_URL}/client-engagement/get-active-and-past-engagement`;

//hr engagement scope

export const GET_HR_ENGAGEMENT_SCOPE_DETAILS = `${ASSESSMENT_CUSTOMER_BASE_URL}/client-engagement/hr`;



//manager Leadershsip Profile

export const GET_ALL_CLIENTS_MANAGER = `${ASSESSMENT_CUSTOMER_BASE_URL}/client-reporting-mapping/get-all-client-for-manager`;
export const GET_MANAGER_CLIENT_STRENGTS_BY_ID = `${ASSESSMENT_CUSTOMER_BASE_URL}/assessment/all-strength-for-manager`;
export const GET_MANAGER_CLIENT_IMPROVEMENT_BY_ID = `${ASSESSMENT_CUSTOMER_BASE_URL}/assessment/all-area-of-improvement-for-manager`;
export const GET_MANAGER_CORE_VALUES_BY_ID = `${ASSESSMENT_CUSTOMER_BASE_URL}/leadership-core-value/all-leadership-core-value-for-manager`;
//manager dashboard
export const GET_MANAGER_DASHBOARD_KEY_ENGAGEMENT = `${ASSESSMENT_CUSTOMER_BASE_URL}/client-engagement/manager`;
export const GET_MANAGER_DASHBOARD_LATEST_ENGAGEMENT = `${ASSESSMENT_CUSTOMER_BASE_URL}/authentication/manager/top-five-client`;

//manager Coaching focus

export const GET_MANAGER_FOCUS_BY_ID = `${ASSESSMENT_CUSTOMER_BASE_URL}/coaching-focus/all-coaching-focus-for-manager`;


//client Rating
export const CLIENT_LATEST_OUTCOMES = `${ASSESSMENT_CUSTOMER_BASE_URL}/outcome/client/latest-outcome`
export const GET_ALL_PREVIOUS_OUTCOMES_DATA = `${ASSESSMENT_CUSTOMER_BASE_URL}/outcome/client/get-all-generate-outcome-date`;
export const CLIENT_PREVIOUS_OUTCOMES_DATA_BY_ID = `${ASSESSMENT_CUSTOMER_BASE_URL}/outcome/client/get-generated-outcome-by-id`;
export const SHARE_WITH_HR_OUTCOMES = `${ASSESSMENT_CUSTOMER_BASE_URL}/outcome/client/share-with-hr`;
export const SHARE_WITH_MANAGER_OUTCOMES = `${ASSESSMENT_CUSTOMER_BASE_URL}/outcome/client/share-with-manager`;
export const ACCEPT_BY_CLIENT_OUTCOME = `${ASSESSMENT_CUSTOMER_BASE_URL}/outcome/client/approve-outcome`;

// duplicates
export const HR_RATING_BY_CLIENT = `${ASSESSMENT_CUSTOMER_BASE_URL}/mentor-to-client-rating/report-hr`;
export const GET_RATING_BY_CLIENT = `${ASSESSMENT_CUSTOMER_BASE_URL}/mentor-to-client-rating/report-client`;
export const MANAGER_RATING_BY_CLIENT = `${ASSESSMENT_CUSTOMER_BASE_URL}/mentor-to-client-rating/report-manager`;

//client reports
export const ACCEPT_BY_CLIENT_SUMMARY = `${ASSESSMENT_CUSTOMER_BASE_URL}/summary/client/approve-summary`;
export const CLIENT_LATEST_SUMMARY = `${ASSESSMENT_CUSTOMER_BASE_URL}/summary/client/latest-summary`;
export const GET_ALL_PREVIOUS_SUMMARY_DATA = `${ASSESSMENT_CUSTOMER_BASE_URL}/summary/client/get-all-generate-summary-date`;
export const SHARE_WITH_HR_SUMMARY = `${ASSESSMENT_CUSTOMER_BASE_URL}/summary/client/share-with-hr`;
export const SHARE_WITH_MANAGER_SUMMARY = `${ASSESSMENT_CUSTOMER_BASE_URL}/summary/client/share-with-manager`;
export const CLIENT_PREVIOUS_SUMMARY_DATA_BY_ID = `${ASSESSMENT_CUSTOMER_BASE_URL}/summary/client/get-generated-summary-by-id`;

//duplicates
export const CURRENT_ROLES = `${ASSESSMENT_CUSTOMER_BASE_URL}/authentication/current-user`;
export const CLIENT_ENGAGEMENT_REPORTS = `${ASSESSMENT_CUSTOMER_BASE_URL}/summary/client-report`;
export const HR_ENGAGEMENT_REPORTS = `${ASSESSMENT_CUSTOMER_BASE_URL}/client-engagement-checkin/hr-report`;

//hr summary
export const HR_PREVIOUS_SUMMARY_DATA = `${ASSESSMENT_CUSTOMER_BASE_URL}/summary/hr/get-all-generate-summary-date`;
export const HR_LATEST_SUMMARY = `${ASSESSMENT_CUSTOMER_BASE_URL}/summary/hr/latest-summary`;
export const HR_PREVIOUS_SUMMARY_DATA_BY_ID = `${ASSESSMENT_CUSTOMER_BASE_URL}/summary/hr/get-generated-summary-by-id`;

//hr outcomes
export const HR_PREVIOUS_OUTCOMES_DATA = `${ASSESSMENT_CUSTOMER_BASE_URL}/outcome/hr/get-all-generate-outcome-date`;
export const HR_LATEST_OUTCOMES = `${ASSESSMENT_CUSTOMER_BASE_URL}/outcome/hr/latest-outcome`;
export const HR_PREVIOUS_OUTCOMES_DATA_BY_ID = `${ASSESSMENT_CUSTOMER_BASE_URL}/outcome/hr/get-generated-outcome-by-id`;


//manager summary
export const MANAGER_PREVIOUS_SUMMARY_DATA = `${ASSESSMENT_CUSTOMER_BASE_URL}/summary/manager/get-all-generate-summary-date`;
export const MANAGER_LATEST_SUMMARY = `${ASSESSMENT_CUSTOMER_BASE_URL}/summary/manager/latest-summary`;
export const MANAGER_PREVIOUS_SUMMARY_DATA_BY_ID = `${ASSESSMENT_CUSTOMER_BASE_URL}/summary/manager/get-generated-summary-by-id`;

//Manager outcomes
export const MANAGER_PREVIOUS_OUTCOMES_DATA = `${ASSESSMENT_CUSTOMER_BASE_URL}/outcome/manager/get-all-generate-outcome-date`;
export const MANAGER_LATEST_OUTCOMES = `${ASSESSMENT_CUSTOMER_BASE_URL}/outcome/manager/latest-outcome`;
export const MANAGER_PREVIOUS_OUTCOMES_DATA_BY_ID = `${ASSESSMENT_CUSTOMER_BASE_URL}/outcome/manager/get-generated-outcome-by-id`;

// notification
export const GET_ALL_NOTIFICATION = `${ASSESSMENT_CUSTOMER_BASE_URL}/notification-service`

export const GET_LVA = `${ASSESSMENT_CUSTOMER_BASE_URL}/lva-document/get-client-lva-document`











