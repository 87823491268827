import { configureStore } from '@reduxjs/toolkit';
import authReducer from "../features/auth/authSlice"
import dashBoardReducer from "../features/dashboard/dashBoardSlice"
import forgetPass from "../features/auth/forgotPasswordSlice"
import notificationDetails from "../features/notificationSlice"




export const store = configureStore({
  reducer: {
    auth: authReducer,
    dashBoard:dashBoardReducer,
    notification: notificationDetails,
    forgetPass: forgetPass
    
 
  },
});


