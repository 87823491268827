export const ASSESSMENT_CUSTOMER_BASE_URL =
  process.env.REACT_APP_ASSESSMENT_CUSTOMER_BASE_URL ||"https://test.ouraipro.com/assessment"

  export const BASE_HR_URL =
  process.env.REACT_APP_ASSESSMENT_CUSTOMER_BASE_URL || "https://www.ouraipro.com"

export const ROLE_URL =  process.env.REACT_APP_ROLE_URL || "https://www.ouraipro.com/excoadmin";


export const MICRO_SITE_URL =process.env.REACT_APP_MICRO_SITE_URL || "https://www.ouraipro.com/microsite"

export const MICRO_SITE_MOCK_URL = process.env.REACT_APP_MICRO_SITE_MOCK_URL  

export const MICRO_SITE_CUSTOM_TEXT =process.env.REACT_APP_MICRO_SITE_CUSTOM_TEXT || ""

export const MICRO_SITE_VERSION = process.env.REACT_APP_MICRO_SITE_VERSION || ""

export const MENTOR_VERSION = process.env.REACT_APP_MENTOR_VERSION || ""
// export REACT_APP_MENTOR_CUSTOM_TEXT=TEST
// export REACT_APP_MENTOR_VERSION=V0.0.2


export const CUSTOMER_LOGO = process.env.REACT_APP_CUSTOMERID 

export const CUSTOMER_LOGO_URL = `${MICRO_SITE_URL}/static/${CUSTOMER_LOGO}`





