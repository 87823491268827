import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {
  GET_ENGAGEMENT_DETAIL,
  GET_CLIENT_ENGAGEMENT_STAGE,
  GET_CURRENT_MENTOR_NAME,
  ASSESSMENT_TRACKING_SELF,
  ASSESSMENT_TRACKING_TRACK_TWO,
  ASSESSMENT_TRACKING_STAKEHOLDER,
  ASSESSMENT_TRACKING_TRACK_ONE,
  ADD_TRACKING_QUESTIONS,
  FINISH_ASSESSMENT_API
} from "../../utils/routes";

// ---------------------------------------------------------------------

// self

export const getSelfLinks = createAsyncThunk("assessment/self", async () => {
  return axios.get(`${ASSESSMENT_TRACKING_SELF}`, { withCredentials: true });
});

//stakeholders

export const getAssessmentStakeholder = createAsyncThunk(
  "assessment/stakeholder",
  async () => {
    return axios.get(ASSESSMENT_TRACKING_STAKEHOLDER, {
      withCredentials: true,
    });
  }
);

export const assessmentTrackingTemplate = createAsyncThunk(
  "assessment/startTemplate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(ASSESSMENT_TRACKING_TRACK_ONE,data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      rejectWithValue(err.response.data);
    }
  }
);

export const assessmentTrackingTemplateForClients = createAsyncThunk(
  "assessment/startTemplateClient",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(ASSESSMENT_TRACKING_TRACK_TWO,data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      rejectWithValue(err.response.data);
    }
  }
);


export const addingQuestionTemplate = createAsyncThunk(
  "assessment/questionTrackig",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(ADD_TRACKING_QUESTIONS,data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      rejectWithValue(err.response.data);
    }
  }
);

export const finishAssessmentForClients = createAsyncThunk(
  "finish/rated",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(FINISH_ASSESSMENT_API, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCurrentMentorName = createAsyncThunk(
  "dashboard/getCurrentMentor",
  async () => {
    return axios.get(GET_CURRENT_MENTOR_NAME, { withCredentials: true });
  }
);




const engagementSlice = createSlice({
  name: "assessment",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    engagementsData: [],
    engagementStageData: [],
    selfLinkData: [],
    stakeHolderData: [],
    assessmentTemplateData:[],
    mentorCurrentDetails: {},
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
    assessmentEmpty:(state,)=>{
    state.assessmentTemplateData = []
    }
  },
  extraReducers: {
    [getSelfLinks.pending]: (state, action) => {
      state.loading = true;
    },
    [getSelfLinks.fulfilled]: (state, action) => {
      state.loading = false;
      state.selfLinkData = action.payload.data;
    },
    [getSelfLinks.rejected]: (state, action) => {
      state.loading = false;
    },
    [finishAssessmentForClients.pending]: (state, action) => {
      state.loading = true;
    },
    [finishAssessmentForClients.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [finishAssessmentForClients.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [addingQuestionTemplate.pending] : (state,action)=>{
      state.loading = true;
    },
    [addingQuestionTemplate.pending] : (state,action)=>{
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [addingQuestionTemplate.pending] : (state,action)=>{
      state.loading = false
      state.errMessage = action?.payload?.data?.message;
    },


    [assessmentTrackingTemplate.pending] : (state,action) =>{
      state.loading = true
    },
    [assessmentTrackingTemplate.fulfilled] : (state,action) =>{
      
      state.loading = false
      state.assessmentTemplateData = action.payload === undefined ? []: action.payload.data

    },
    [assessmentTrackingTemplate.rejected] : (state,action) =>{
      state.loading = false
    },

    [assessmentTrackingTemplateForClients.pending] : (state,action) =>{
      state.loading = true
    },
    [assessmentTrackingTemplateForClients.fulfilled] : (state,action) =>{
      
      state.loading = false
      state.assessmentTemplateData = action.payload === undefined ? []: action.payload.data

    },
    [assessmentTrackingTemplateForClients.rejected] : (state,action) =>{
      state.loading = false
    },


    [getAssessmentStakeholder.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentStakeholder.fulfilled]: (state, action) => {
      state.loading = false;
      state.stakeHolderData = action.payload.data;
    },
    [getAssessmentStakeholder.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getCurrentMentorName.pending]: (state, action) => {
      state.loading = true;
    },
    [getCurrentMentorName.fulfilled]: (state, action) => {
      
      state.loading = false;
      state.mentorCurrentDetails = action?.payload?.data;
    },
    [getCurrentMentorName.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

   
   
  },
});

export const { resetMessage, resetErrMessage,assessmentEmpty, rowSelect } =
  engagementSlice.actions;
export default engagementSlice.reducer;
