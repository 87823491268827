import React, { useEffect, useState } from "react";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material ui
import {
  Stack,
  Box,
  Grid,
  Paper,
  Input,
  IconButton,
  Avatar,
  ListItem,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

// components
import Layout from "../../layout/Layout";
import ErrorNotifier from "../../components/common/ErrorNotifier";
import { Notifier } from "../../components";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
// redux
import {
  uploadProfilePic,
  resetMessage,
  resetErrMessage,
  isCheckingRoles,
  getCurrentUser,
  isLoggedIn,
} from "../../features/auth/authSlice";
import { ASSESSMENT_CUSTOMER_BASE_URL } from "../../utils/config";

// ------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "0.5rem",
    borderRadius: "1rem !important",
  },
  muiList: {
    padding: "0 !important",
   
  },
  muiListHeadText: {
    width: "3.4rem",
  },
  muiListDataText: {
    width: "3rem",
    wordWrap: "break-word",
  },
}));

// ---------------------------------------------------------------

const MyProfile = () => {
  const [customMessage, setCustomMessage] = useState("");
  const dispatch = useDispatch();
  const { message, errMessage, loginData,  getAllClientData } = useSelector(
    (state) => ({ ...state.auth })
  );
  const classes = useStyles();
  const Navigate = useNavigate();

  const handleFile = (e) => {
    
    if (/\.(jpe?g|png|gif|bmp)$/i.test(e.target.files[0].name)) {
      if ((e.target.files[0].size / 1024 / 1024).toFixed(1) < 1) {
        let formdata = new FormData();
        formdata.append("profilePic", e.target.files[0]);

        dispatch(uploadProfilePic(formdata)).then((res) => {
          if (res?.payload?.data?.ok) {
            setTimeout(() => {
              dispatch(resetMessage());
              dispatch(isLoggedIn());
              //  Navigate("/exco/my-profile")
            }, 1000);
          }
          if (res?.payload?.error) {
            setTimeout(() => {
              dispatch(resetErrMessage());
            }, 3000);
          }
        });
      } else {
        console.log("invalid format");
        setCustomMessage("File size must less than 2MB");
        setTimeout(() => {
          setCustomMessage("");
        }, 2000);
      }
    } else {
      console.log("invalid format one");
      setCustomMessage("Please attach valid file format");
      setTimeout(() => {
        setCustomMessage("");
      }, 2000);
    }
  };

  useEffect(()=>{
  dispatch(getCurrentUser())
  },[])

  useEffect(() => {
    return <ProfilePic />;
  }, []);



  const ProfilePic = () => {
    return (
      <Grid container spacing={2} sx={{ justifyContent: "center" }}>
        <Grid item xs={12} sm={12} md={6}>
          <Paper
            elevation={4}
            className={classes.paper}
            sx={{ textAlign: "center" }}
          >
            <Avatar
              alt="Remy Sharp"
              src={`${ASSESSMENT_CUSTOMER_BASE_URL}/v1/api/assessment-customer-user/profile-pic`}
              sx={{ width: 100, height: 100, margin: "auto" }}
            />
            <label htmlFor="icon-button-file">
              <Input
                accept="image/*"
                id="icon-button-file"
                type="file"
                sx={{ display: "none" }}
                name="l1MenuIcon"
                onChange={handleFile}
                required={true}
              />
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <PhotoCamera />
              </IconButton>
            </label>
          </Paper>
        </Grid>
      </Grid>
    );
  };

  return (
    <Layout>
      <Stack flexDirection="row" justifyContent="space-between">
        <Box sx={{ width: "100%", marginTop: "2rem" }}>
          <PageHeader variant="pageHeader">My Profile</PageHeader>
        </Box>
        <Link to="/exco/dashboard">
          <CancelIcon sx={{ marginTop: "1rem" }} color="red" />
        </Link>
      </Stack>
      <ProfilePic />
      <Grid container sx={{ justifyContent: "center", mt: 2 }}>
        <Grid item xs={12} sm={12} md={6}>
          <Paper elevation={4} className={classes.paper}>
            <ListItem className={classes.muiList}>
              <ListItemText className={classes.muiListHeadText}>
                User Name
              </ListItemText>
              &nbsp; :&nbsp;{" "}
              <ListItemText className={classes.muiListDataText}>
                { getAllClientData?.data?.getAssessmentCustomerUser?.assessmentCustomerUserDetail?.firstName}&nbsp;
                {  getAllClientData?.data?.getAssessmentCustomerUser?.assessmentCustomerUserDetail?.lastName}
              </ListItemText>
            </ListItem>
            <ListItem className={classes.muiList}>
              <ListItemText className={classes.muiListHeadText}>
                Email
              </ListItemText>
              &nbsp; :&nbsp;{" "}
              <ListItemText s className={classes.muiListDataText}>
                { getAllClientData?.data?.getAssessmentCustomerUser?.email}
              </ListItemText>
            </ListItem>
            {/* <ListItem className={classes.muiList}>
              <ListItemText className={classes.muiListHeadText}>
                Geography
              </ListItemText>
              &nbsp; :&nbsp;{" "}
              <ListItemText className={classes.muiListDataText}>
                {(getAllClientData?.data?.getAssessmentCustomerUser?.assessmentCustomerUserDetail?.geoName === "AF" &&
                  "Africa(AF)") ||
                  (getAllClientData?.data?.getAssessmentCustomerUser?.assessmentCustomerUserDetail?.geoName === "AN" &&
                    "Antarctica(AN)") ||
                  (getAllClientData?.data?.getAssessmentCustomerUser?.assessmentCustomerUserDetail?.geoName === "AS" &&
                    "Asia(AS)") ||
                  (getAllClientData?.data?.getAssessmentCustomerUser?.assessmentCustomerUserDetail?.geoName === "EU" &&
                    "Europe(EU)") ||
                  (getAllClientData?.data?.getAssessmentCustomerUser?.assessmentCustomerUserDetail?.geoName === "OC" &&
                    "Oceania(OC)") ||
                  (getAllClientData?.data?.getAssessmentCustomerUser?.assessmentCustomerUserDetail?.geoName === "NA" &&
                    "North America(NA)") ||
                  (getAllClientData?.data?.getAssessmentCustomerUser?.assessmentCustomerUserDetail?.geoName === "SA" &&
                    "South America(SA)")}
              </ListItemText>
            </ListItem> */}
            {/* <ListItem className={classes.muiList}>
              <ListItemText className={classes.muiListHeadText}>
                Country
              </ListItemText>
              &nbsp; :&nbsp;{" "}
              <ListItemText className={classes.muiListDataText}>
                {getAllClientData?.data?.getAssessmentCustomerUser?.assessmentCustomerUserDetail?.country}
              </ListItemText>
            </ListItem>
            <ListItem className={classes.muiList}>
              <ListItemText className={classes.muiListHeadText}>
                Contact Number
              </ListItemText>
              &nbsp; :&nbsp;{" "}
              <ListItemText className={classes.muiListDataText}>
                {getAllClientData?.data?.getAssessmentCustomerUser?.assessmentCustomerUserDetail?.dialingCode}-
                {getAllClientData?.data?.getAssessmentCustomerUser?.assessmentCustomerUserDetail?.contactNumber}
              </ListItemText>
            </ListItem> */}
          </Paper>
        </Grid>
      </Grid>
      {customMessage && <ErrorNotifier message={customMessage} />}
    </Layout>
  );
};

export default MyProfile;
