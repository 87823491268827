import React, { useEffect, useState } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { MyTextInput, PageHeader } from "@excoleadershipui/pionweblib";
// material ui
import {
  Typography,
  Container,
  Box,
  Grid,
  Link,
  Paper,
  IconButton,
  Stack,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
// icons
import logo from "../../assets/images/exco-nav-logo.svg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
// components
import { FormSubmitButton } from "../../components";
import Notifier from "../../components/common/Notifier";
import ErrorNotifier from "../../components/common/ErrorNotifier";
import { TryRounded } from "@mui/icons-material";
// redux

import {
  verifyEmail,
  passVerify,
  changePasswordVerify,
  login,
  resetMessage,
  resetErrMessage,
  isLoggedIn,
} from "../../features/auth/authSlice";
import { BsTwitterX } from "react-icons/bs";
// import { MENTOR_VERSION} from "../../utils/config"

// -----------------------------------------------------

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.excoleadership.com/">
        The ExCo Group LLC
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

//   -----------------------------------------------------------------------------------------

function SocialMedia(props) {
  return (
    <Box textAlign="center" sx={{ marginTop: 2 }}>
      <Typography variant="body2">Stay Social</Typography>
      <Stack
        direction="row"
        spacing={1}
        divider={<Divider orientation="vertical" flexItem />}
        justifyContent="center"
      >
        <a
          style={{ textDecoration: "none", color: "#00527d" }}
          href="https://www.linkedin.com/company/excoleadership/"
        >
          <LinkedInIcon textAlign="center" />
        </a>
        <a
          style={{ textDecoration: "none", color: "#00527d" }}
          href="https://twitter.com/ExCoLeadership"
        >
          <h3
            // data-toggle="tooltip"
            // data-placement="top"
            // onClick={this.handleAssignedView}
            style={{
               marginTop: "-0.01rem",
              cursor: "pointer",
              fontSize: "1.2rem",
            }}
          >
            {" "}
            <BsTwitterX  />{" "}
          </h3>
        </a>
      </Stack>
    </Box>
  );
}

// -------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  btnSubmit: {
    marginTop: "1rem !important",
  },
  errorDiv: {
    color: theme.palette.red.main,
  },
}));

// ---------------------------------------------------------------------------------------------

const EmailValidationSchema = Yup.object({
  email: Yup.string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

const validationSchema = Yup.object({
  tempPassword: Yup.string().required("Temporary Password  is required"),

  newPassword: Yup.string()
    .required("New Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character (!@#$%^&)"
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});

const verifyCodeValidationSchema = Yup.object({
  verifyCodeValue: Yup.string().required("Enter your verificatin code"),
});

const PassValidationSchema = Yup.object({
  password: Yup.string("Enter your password").required("Password is required"),
});

// ---------------------------------------------------------------------------------------------

const SignIn = (props) => {
  const [showEmail, setShowEmail] = useState(true);
  const [showPass, setShowPass] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [email, setEmail] = useState("mohan@123");
  const [verifyCode, setVerifyCode] = useState(false);
  const [baseString, setBaseString] = useState("")
  let navigate = useNavigate();
  const classes = useStyles();

  const dispatch = useDispatch();

  const {
    loading,
    loginToken,
    rq,
    message,
    passToken,
    // baseString,
    errMessage,
    loggedIn,
  } = useSelector((state) => ({
    ...state.auth,
  }));

  console.log(baseString)
  const handlePass = () => {
    setShowPass(true);
    setShowEmail(false);
    setVerifyCode(false);
    setChangePassword(false);
  };

  const handleChangePass = () => {
    setChangePassword(true);
    setShowPass(false);
    setShowEmail(false);
    setVerifyCode(false);
  };

  const handleVerifyCode = () => {
    setShowPass(false);
    setShowEmail(false);
    setVerifyCode(true);
    setChangePassword(false);
  };

  useEffect(() => {
    dispatch(isLoggedIn());
  }, []);

  useEffect(() => {
    return loggedIn && navigate("/exco/dashboard");
  }, [loggedIn]);

  //   console.log(loggedIn)

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 4 }}>
        <PageHeader sx={{ marginTop: 4 }}>ExCo Sign In </PageHeader>
      </Box>

      <Paper
        sx={{
          marginTop: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "2rem",
          position: "relative",
          border: "1px solid",
        }}
        elevation={2}
      >
        {showPass && (
          <IconButton
            sx={{ position: "absolute", left: "0.5rem", top: "0.5rem" }}
            onClick={() => {
              setShowEmail(true);
              setShowPass(false);
            }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
        )}
        {changePassword && (
          <IconButton
            sx={{ position: "absolute", left: "0.5rem", top: "0.5rem" }}
            onClick={() => {
              setShowEmail(true);
              setChangePassword(false);
            }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
        )}

        {verifyCode && (
          <IconButton
            sx={{ position: "absolute", left: "0.5rem", top: "0.5rem" }}
            onClick={() => {
              setShowEmail(false);
              setVerifyCode(false);
              setShowPass(true);
            }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
        )}
        <img alt="logo" src={logo} width="80rem" height="40rem" />
        <Typography variant="pageHeader">Sign in</Typography>
        <Formik
          initialValues={{
            email: "",
            password: "",
            verifyCodeValue: "",

            newPassword: "",
            confirmPassword: "",
            tempPassword: "",
          }}
          validationSchema={
            (showEmail && EmailValidationSchema) ||
            (showPass && PassValidationSchema) ||
            (verifyCode && verifyCodeValidationSchema) ||
            (changePassword && validationSchema)
          }
          onSubmit={(values, { setSubmitting }) => {
            if (showEmail) {
              values.email && setEmail(values.email);
              // handlePass();
              return dispatch(verifyEmail({ email: values.email })).then(
                (res) => {
                  if (res?.payload?.data) {
                    if (res?.payload?.data?.data?.requiresPasswordChange) {
                      setTimeout(() => {
                        dispatch(resetMessage());
                        handleChangePass();
                      }, 2000);
                    } else {
                      setTimeout(() => {
                        dispatch(resetMessage());
                        handlePass();
                      }, 200);
                    }
                  }
                  if (res?.payload?.error) {
                    setTimeout(() => {
                      dispatch(resetErrMessage());
                    }, 3000);
                  }
                  if (res?.payload?.warn) {
                    setTimeout(() => {
                      dispatch(resetErrMessage());
                    }, 3000);
                  }
                }
              );
            }
            if (changePassword) {
              return dispatch(
                changePasswordVerify({
                  password: values.tempPassword,
                  newPassword: values.newPassword,
                  rq: loginToken,
                })
              ).then((res) => {
                if (res?.payload?.data?.ok) {
                  setBaseString(res?.payload?.data?.data?.baseString)
                  setTimeout(() => {
                    dispatch(resetMessage());
                    handleVerifyCode();
                  }, 200);
                }
                if (res?.payload?.error) {
                  setTimeout(() => {
                    dispatch(resetErrMessage());
                  }, 3000);
                }
                if (res?.payload?.warn) {
                  setTimeout(() => {
                    dispatch(resetErrMessage());
                  }, 3000);
                }
              });
            }

            if (showPass) {
              return dispatch(
                passVerify({
                  password: values.password,
                  rq: loginToken,
                })
              ).then((res) => {
                if (res?.payload?.data?.ok) {
                  setBaseString(res?.payload?.data?.data?.baseString)
                  setTimeout(() => {
                    dispatch(resetMessage());
                    handleVerifyCode();
                  }, 200);
                }
                if (res?.payload?.error) {
                  setTimeout(() => {
                    dispatch(resetErrMessage());
                  }, 3000);
                }
                if (res?.payload?.warn) {
                  setTimeout(() => {
                    dispatch(resetErrMessage());
                  }, 3000);
                }
              });
            }

            if (verifyCode) {
              dispatch(
                login({
                  token: values.verifyCodeValue.toString(),
                  rq: passToken,
                })
              ).then((res) => {
                console.log(res?.payload);
                if (res?.payload?.data?.ok) {
                  dispatch(isLoggedIn());
                  setBaseString("")
                  setTimeout(() => {
                    dispatch(resetMessage());
                    navigate("/exco/dashboard");
                  }, 200);
                }

                if (res?.payload?.error) {
                  setTimeout(() => {
                    dispatch(resetErrMessage());
                  }, 3000);
                }
                if (res?.payload?.warn) {
                  setTimeout(() => {
                    dispatch(resetErrMessage());
                  }, 3000);
                }
              });
            }
          }}
        >
          {({ errors, touched, handleChange, handleSubmit, values }) =>
            loading ? (
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box
                component="form"
                noValidate
                sx={{ mt: 1 }}
                onSubmit={handleSubmit}
              >
                {changePassword && (
                  <MyTextInput
                    label="Temporary Password found in your email"
                    type="text"
                    name="tempPassword"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="tempPassword"
                    onChange={handleChange("tempPassword")}
                    value={values?.tempPassword}
                    error={touched.tempPassword && Boolean(errors.tempPassword)}
                    helperText={touched.tempPassword && errors.tempPassword}
                  />
                )}

                {changePassword && (
                  <MyTextInput
                    label="New Password"
                    type="password"
                    name="newPassword"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="newPassword"
                    value={values?.newPassword}
                    onChange={handleChange("newPassword")}
                    error={touched.newPassword && Boolean(errors.newPassword)}
                    helperText={touched.newPassword && errors.newPassword}
                  />
                )}

                {changePassword && (
                  <MyTextInput
                    label="Confirm Password"
                    type="password"
                    name="confirmPassword"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="confirmPassword"
                    value={values?.confirmPassword}
                    onChange={handleChange("confirmPassword")}
                    error={
                      touched.confirmPassword && Boolean(errors.confirmPassword)
                    }
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                  />
                )}

                {changePassword && (
                  <FormSubmitButton
                    type="submit"
                    fullWidth={true}
                    variant="contained"
                    // onClick={handleSubmit}
                    className={classes.btnSubmit}
                  >
                    Change Password
                  </FormSubmitButton>
                )}

                {showEmail && (
                  <MyTextInput
                    label="Email Address"
                    type="email"
                    name="email"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="email"
                    onChange={handleChange("email")}
                    value={values?.email}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    autoFocus
                  />
                )}
                {showPass && (
                  <MyTextInput
                    label="Password"
                    type="password"
                    name="password"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="password"
                    onChange={handleChange("password")}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    autoFocus
                  />
                )}

                {verifyCode && (
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "25%", paddingRight: "0.5rem" }}>
                      <MyTextInput
                        // label="Dialing Code"
                        type="string"
                        name="baseString"
                        fullWidth={true}
                        margin="normal"
                        id="baseString"
                        value={baseString}
                        disabled
                      />
                    </div>
                    <div style={{ width: "75%" }}>
                      <MyTextInput
                        label="One Time Pin Found in Your Email"
                        type="number"
                        name="verifyCodeValue"
                        required={true}
                        fullWidth={true}
                        margin="normal"
                        id="verify"
                        onChange={handleChange("verifyCodeValue")}
                        error={
                          touched.verifyCodeValue &&
                          Boolean(errors.verifyCodeValue)
                        }
                        helperText={
                          touched.verifyCodeValue && errors.verifyCodeValue
                        }
                        autoFocus
                      />
                    </div>
                  </div>
                )}

                {verifyCode && (
                  <FormSubmitButton
                    type="submit"
                    fullWidth={true}
                    variant="contained"
                    className={classes.btnSubmit}
                  >
                    Verify
                  </FormSubmitButton>
                )}

                {verifyCode && (
                  <Stack
                    sx={{
                      fontSize: "0.6rem",
                      textAlign: "center",
                      marginTop: "1rem",
                    }}
                  >
                    We've sent a verification code to the email address you
                    provided. Please check your inbox and enter six digit code
                    in above box and click verify
                  </Stack>
                )}

                {showEmail && (
                  <FormSubmitButton
                    type="submit"
                    fullWidth={true}
                    variant="contained"
                    className={classes.btnSubmit}
                  >
                    Next
                  </FormSubmitButton>
                )}
                {showPass && (
                  <FormSubmitButton
                    type="submit"
                    fullWidth={true}
                    variant="contained"
                    className={classes.btnSubmit}
                  >
                    Sign In
                  </FormSubmitButton>
                )}
                {showPass && (
                  <Grid container>
                    <Grid item xs>
                      <Link href="/forgot-password" variant="body2">
                        Forgot password?
                      </Link>
                    </Grid>
                  </Grid>
                )}
              </Box>
            )
          }
        </Formik>
      </Paper>
      <SocialMedia />
      <Copyright sx={{ mt: 8, mb: 4 }} />
      <Outlet />
      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Container>
  );
};

export default SignIn;
