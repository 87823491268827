import { createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
// routes
import {
  EMAIL_VERIFY,
  LOGIN_ACCOUNT,
  LOGOUT_ACCOUNT,
  CREATE_ACCOUNT,
  GET_ACCOUNTS,
  ACTIVATE_ACCOUNT,
  GET_ALL_ROLES,
  IS_LOGGED_IN,
  CURRENT_ROLES,
  LOGGED_IN_ROLES,
  UPLOAD_PROFILE_PIC ,
  PASS_PASSWORD_ACCOUNT,
  CURRENT_USER_DETAILS_API,
  CHANGE_PASSWORD_ACCOUNT_API
} from "../../utils/routes";

// ---------------------------------------------------------------------------------

export const verifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(EMAIL_VERIFY, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
async (data, { rejectWithValue }) => {
  let authRQ = data.rq
  delete data.rq
  
   try {
    const response = await axios.post(LOGIN_ACCOUNT, data, {
      headers: {
        Authorization: `Bearer ${authRQ}`,
      "Content-Type": "application/json",
      },
     withCredentials: true,
    }) ;
    return response

     
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const passVerify = createAsyncThunk("pass/verify",
async(data,{rejectWithValue})=>{
  
  let authRQ = data.rq
  delete data.rq
try{
  const response = await axios.post(PASS_PASSWORD_ACCOUNT, data, {
    headers: {
      Authorization: `Bearer ${authRQ}`,
    "Content-Type": "application/json",
    },
   withCredentials: true,
  }) ;
  
  return response

}
catch(err){
return rejectWithValue(err.response.data)
}
}

)

export const changePasswordVerify = createAsyncThunk("pass/verify",
async(data,{rejectWithValue})=>{
 
  let authRQ = data.rq
  delete data.rq
try{
  const response = await axios.post(CHANGE_PASSWORD_ACCOUNT_API, data, {
    headers: {
      Authorization: `Bearer ${authRQ}`,
    "Content-Type": "application/json",
    },
   withCredentials: true,
  }) ;
  return response

}
catch(err){
return rejectWithValue(err.response.data)
}
}

)


export const isLoggedIn = createAsyncThunk(
  "login/isLoggedIn",
  async () => {
    return axios.get(IS_LOGGED_IN, { withCredentials: true });
  }
);

export const isLoggedVerifyRoles = createAsyncThunk(
  "login/isLoggedInVerifyRoles",
  async () => {
    return axios.get(LOGGED_IN_ROLES, { withCredentials: true });
  }
);

export const isCheckingRoles = createAsyncThunk(
  "auth/checkRole",
  async () => {
    return axios.get(CURRENT_ROLES, { withCredentials: true });
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(LOGOUT_ACCOUNT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAccount = createAsyncThunk(
  "auth/getAccount",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_ACCOUNTS, { withCredentials: true });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const activateAccount = createAsyncThunk(
  "auth/activateAccount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(ACTIVATE_ACCOUNT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCurrentUser = createAsyncThunk(
  "assessmentuser/details",
  async (_,{rejectWithValue})=>{
    try{
      const response = await axios.get(CURRENT_USER_DETAILS_API,{withCredentials:true});
      return response
    }
    catch(err){
      return rejectWithValue(err.response.data)
    }

  }
)

export const uploadProfilePic = createAsyncThunk(
  "auth/uploadProfilePic",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(UPLOAD_PROFILE_PIC , data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const saveToLocalStorage = createAsyncThunk(
  "auth/saveLocalStorage",
  async (state ) => {
    try {
      localStorage.setItem('state', JSON.stringify(state));
    } catch (e) {
      console.error(e);
    }
  }
);

export const loadFromLocalStorage = createAsyncThunk(
  "auth/loadFromLocal",
  async ( ) => {
    try {
      const stateStr = localStorage.getItem('state');
      return stateStr ? JSON.parse(stateStr) : undefined;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
);




// export const saveToLocalStorage = () => {
//   try {
//     localStorage.setItem('state', JSON.stringify("chandra"));
//   } catch (e) {
//     console.error(e);
//   }
// };

// const loadFromLocalStorage = () => {
//   try {
//     const stateStr = localStorage.getItem('state');
//     return stateStr ? JSON.parse(stateStr) : undefined;
//   } catch (e) {
//     console.error(e);
//     return undefined;
//   }
// };

// const rootReducer = combineReducers({
//   list: listReducer
// });



// const store = configureStore(rootReducer, persistedStore);

// store.subscribe(() => {
//   saveToLocalStorage(store.getState());
// });

// ---------------------------------------------------------------------------------

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    loginToken: "",
    rq: "",
    data: [],
    accountsData: [],
    getAllClientData:[],
    rolesApiData:[],
    allRoleData:[],
    passToken:"",
    baseString: "",
    rolesErrMessage:"",
    customerCustomMessage:"",
    localStoreRoleData:"",
    loggedIn:null,
    custMessage:"",
    logginRolesData:"",
    rolesData:"",
    message: "",
    errMessage: "",
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },

    localStoreRoleFunc :(state,action) =>{
    state.localStoreRoleData = action.payload
    },
   
    rolesErrMessageFunc:(state)=>{
    state.rolesErrMessage = "Roles should not be empty"
    },
    
    rolesErrMessageEmptyFunc:(state)=>{
    state.rolesErrMessage = ""
    },
      
    customerLoggedInFunc :(state)=>{
       state.customerCustomMessage =  state.custMessage
    },

    customerLoggedInEmptyFunc :(state)=>{
        state.customerCustomMessage = ""
    },

  },
  extraReducers: {

    [uploadProfilePic.pending]: (state, action) => {
      state.loading = true;
    },
    [uploadProfilePic.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [uploadProfilePic.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [passVerify.pending]: (state, action) => {
      state.loading = true;
    },
    [passVerify.fulfilled]: (state, action) => {
      state.loading = false;
      state.passToken = action.payload.data.data.token
      state.baseString = action.payload.data.data.baseString
      state.message = action?.payload?.data?.message;
    },
    [passVerify.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [changePasswordVerify.pending]: (state, action) => {
      state.loading = true;
    },
    [changePasswordVerify.fulfilled]: (state, action) => {
      state.loading = false;
      state.passToken = action.payload.data.data.token
      state.message = action?.payload?.data?.message;
    },
    [changePasswordVerify.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getAccount.pending]: (state, action) => {
      state.loading = true;
    },
    [getAccount.fulfilled]: (state, action) => {
      state.loading = false;
      state.accountsData = action?.payload?.data;
    },
    [getAccount.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [isLoggedVerifyRoles.pending]: (state, action) => {
      state.loading = true;
    },
    [isLoggedVerifyRoles.fulfilled]: (state, action) => {
      state.loading = false;
      state.logginRolesData = action?.payload?.data;
    },
    [isLoggedVerifyRoles.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [isCheckingRoles.pending]: (state, action) => {
      state.loading = true;
    },
    [isCheckingRoles.fulfilled]: (state, action) => {
      state.loading = false;
      state.rolesApiData = action?.payload?.data;
    },
    [isCheckingRoles.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [isLoggedIn.pending]:(state,action) =>{
      state.loading = true
    },
    [isLoggedIn.fulfilled]:(state,action) =>{
      state.loading = false
      state.loggedIn = action?.payload?.data
    },
    [isLoggedIn.rejected]:(state,action) =>{
      state.loading = true
    },
    [getCurrentUser.pending]: (state, action) => {
      state.loading = true;
    },
    [getCurrentUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.getAllClientData = action?.payload?.data;
    },
    [getCurrentUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [verifyEmail.pending]: (state, action) => {
      // state.loading = true;
    },
    [verifyEmail.fulfilled]: (state, action) => {
      
      state.loading = false;
      state.loginToken = action?.payload?.data?.data?.token;
      state.rq = action?.payload?.data?.data?.rq;
      state.message = action?.payload?.data?.message;
    },
    [verifyEmail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [login.pending]: (state, action) => {
      state.loading = true;
    },
    [login.fulfilled]: (state, action) => {
     
    
      state.loading = false;
      state.custMessage = action?.payload?.data?.message; 
      state.message = action?.payload?.data?.message;
    },
    [login.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [logout.pending]: (state, action) => {
      state.loading = true;
    },
    [logout.fulfilled]: (state, action) => {

      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [logout.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [activateAccount.pending]: (state, action) => {
      state.loading = true;
    },
    [activateAccount.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
},
    [activateAccount.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage  = action?.payload?.message;
    },
  },
});

export const { 
  resetMessage, resetErrMessage, rolesErrMessageFunc,rolesErrMessageEmptyFunc
, customerLoggedInEmptyFunc,customerLoggedInFunc,  localStoreRoleFunc , 

} = authSlice.actions;

export default authSlice.reducer;
