import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
// materail ui
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { MICRO_SITE_CUSTOM_TEXT } from "../../utils/config";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Box,
  Toolbar,
  List,
  Divider,
  IconButton,
  FormControl,
  Select,
  Badge,
  MenuItem,
  Menu,
  ListItem,
  Avatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  InputLabel,
  Popover,
  Typography,
  Stack,
  Fab,
} from "@mui/material";
// icons
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
// components
import logo from "../../assets/images/exco-white-logo-one.png";
import shortLogo from "../../assets/images/signin-logo.png";
import { MuiTooltip } from "../../components";
import Notifier from "../../components/common/Notifier";
import ErrorNotifier from "../../components/common/ErrorNotifier";
import { MainListItems } from "./ListItems";
import { ASSESSMENT_CUSTOMER_BASE_URL } from "../../utils/config";

// redux
import { useSelector, useDispatch } from "react-redux";
import { getCurrentUser } from "../../features/auth/authSlice";
import {
  getAllRoles,
  isCheckingRoles,
  logout,
  resetErrMessage,
  resetMessage,
  isLoggedIn,
  localStoreRoleFunc,
  saveToLocalStorage,
} from "../../features/auth/authSlice";
import { getNotification } from "../../features/notificationSlice";

// --------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  MuiLinks: {
    textDecoration: "none",
    color: "inherit",
  },
  MuiFabBtn: {
    position: "fixed !important",
    top: "1rem",
    left: (open) => (open ? "14rem" : "4rem"),
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
    background: `${theme.palette.primary.light} !important`,
    "&:hover": {
      background: `${theme.palette.primary.main} !important`,
    },
    color: "white",
    zIndex: "1500 !important",
    width: "1.563rem !important",
    height: "1.563rem !important",
    minHeight: "0.625rem !important",
    transition: (open) =>
      open
        ? theme.transitions.create(["left"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          })
        : theme.transitions.create(["left"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
  },
  MuiToolbar: {
    paddingRight: "3rem !important",
  },
}));

const drawerWidth = 240;
const drawerWidthMobile = 70;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: "white",
  width: `calc(100% - ${theme.spacing(7)})`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(100% - ${theme.spacing(10)})`,
  },
  [theme.breakpoints.down("sm")]: {
    width: `calc(100% - ${theme.spacing(10)})`,
  },

  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px) !important`,
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${drawerWidthMobile}px) !important`,
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    backgroundColor: theme.palette.sidebarBg.main,
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    [theme.breakpoints.down("sm")]: {
      width: drawerWidthMobile,
    },
    height: "100vh",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(10),
      },
    }),
  },
}));

const BasicMenu = ({ anchorEl, handleClose, open, data }) => {
  return (
    <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
      {data.length > 0 &&
        data
          .filter((item) => item.isRead === "false")
          .map((notif) => (
            <MenuItem onClose={handleClose}>{notif.title}</MenuItem>
          ))}
    </Menu>
  );
};

function DashboardContent() {
  const [open, setOpen] = React.useState(true);
  const [roleOpen, setRoleOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [blink, setBlink] = React.useState(false);
  const [notificationOpen, setNotificationOpen] = React.useState(false);
  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState(null);
  const [roleData, setRoleData] = useState("");
  const [roleHoverOpen, setRoleHoverOpen] = React.useState(false);
  const [roleAnchorE1, setRoleAnchorE1] = React.useState(null);

  const classes = useStyles(open);
  const dispatch = useDispatch();

  const {
    getAllRolesData,
    message,
    errMessage,
    loggedIn,
    rolesData,
    rolesApiData,
    localStoreRoleData,
    getAllClientData,
  } = useSelector((state) => ({
    ...state.auth,
  }));
  const { notificationData } = useSelector((state) => ({
    ...state.notification,
  }));

  const navigate = useNavigate();

  console.log(getAllRolesData);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRoleClick = (event) => {
    setRoleAnchorE1(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRoleClose = () => {
    setRoleAnchorE1(null);
  };

  const handleLogout = () => {
    dispatch(logout()).then((res) => {
      if (res?.payload?.data?.ok) {
        dispatch(isLoggedIn());
        setTimeout(() => {
          dispatch(resetMessage());
          navigate("/");
          window.location.reload();
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  // useEffect(()=>{
  //   const imageUrl = `${ASSESSMENT_CUSTOMER_BASE_URL}/v1/api/assessment-customer-user/profile-pic`
  //   fetch(imageUrl,{credentials: 'include'}).then((response)=>{
  //   console.log(response)
  //    if(response?.ok){
  //     setBlink(true)
  //    }
  //     else if(!response?.ok){
  //      setBlink(false)
  //     }
  //   })
  // },[loggedIn])
  

  // const ProfileAvatar = () => {
  //   if (blink) {
  //     return (
  //       <Avatar
  //         src={`${ASSESSMENT_CUSTOMER_BASE_URL}/v1/api/assessment-customer-user/profile-pic`}
  //         sx={{ fontSize: 34 }}
  //       />
  //     );
  //   } else {
  //     return <Avatar sx={{ fontSize: 34 }} />;
  //   }
  // };

  const ProfileAvatar = () => {
    return <Avatar src={`${ASSESSMENT_CUSTOMER_BASE_URL}/v1/api/assessment-customer-user/profile-pic`}  sx={{fontSize: 34}}/>
  }


  //  useEffect(()=>{
  //   localStorage.getItem('state');

  //  },[ roleData])

  // const ProfileAvatar = () => {
  // return <Avatar src={`${ASSESSMENT_CUSTOMER_BASE_URL}/authentication/profile-pic`}  sx={{fontSize: 34}}/>
  // }

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
    setNotificationOpen(true);
  };

  React.useEffect(() => {
    dispatch(getCurrentUser());
    dispatch(isLoggedIn());
  }, []);

  // console.log(rolesApiData)

  //  useEffect(()=>{
  //   return loggedIn === false  && navigate('/')
  // },[loggedIn])

  const popoverOpen = Boolean(anchorEl);

  const popoverRoleOpen = Boolean(roleAnchorE1);

  const id = open ? "simple-popover" : undefined;

  const roleId = roleOpen ? "simple-popover" : undefined;

  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    if (matches) {
      setOpen(false);
    }
  }, [matches]);

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="absolute" open={open} sx={{ boxShadow: "none" }}>
        <Toolbar className={classes.MuiToolbar}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              sx={{
                color: "#000000",
                textAlign: "center",
                fontSize: "1.5rem",
                marginLeft: "2rem",
              }}
            >
              {MICRO_SITE_CUSTOM_TEXT}
            </Typography>
          </Box>

          <Stack sx={{ display: "flex", flexDirection: "column" }}>
            <MuiTooltip title="User Profile">
              <Box>
                <IconButton
                  sx={{
                    marginRight: "2rem",
                    marginTop: "1rem",
                    paddingBottom: 0,
                    // paddingTop: "0.5rem",
                  }}
                  aria-describedby={id}
                  // onMouseOut={()=> setAnchorEl(null)}
                  onClick={handleClick}
                >
                  <ProfileAvatar />
                </IconButton>
              </Box>
            </MuiTooltip>
            <Typography
              //  onMouseEnter ={rolesApiData?.getExcoCustomerUserRoles?.length > 1 && handleRoleClick}
              varient="body2"
              sx={{
                color: "black !important",
                cursor: "pointer",
                marginLeft: "1rem",
              }}
            >
              {getAllClientData?.data?.getAssessmentCustomerUser?.assessmentCustomerUserDetail?.firstName[0]?.toUpperCase()}
              &nbsp;
              {getAllClientData?.data?.getAssessmentCustomerUser?.assessmentCustomerUserDetail?.lastName[0]?.toUpperCase()}
            </Typography>
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <Box
            edge="start"
            aria-label="open drawer"
            sx={{
              flexGrow: 1,
              textAlign: "start",
              ...(open && { display: "none !important" }),

              // display:{
              //   xs:"block !important",
              //   sm:"none !important"
              // }
            }}
          >
            <img alt="shortLogo" src={shortLogo} width="40" height="40" />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              textAlign: "center",
              ...(!open && { display: "none !important" }),

              // display:{
              //   xs:"none !important",
              //   sm:"block !important"
              // }
            }}
          >
            <img src={logo} alt="nav logo" width="120" height="40" />
          </Box>
        </Toolbar>

        <Divider />
        <List component="nav">
          <MainListItems
            roleData={getAllRolesData}
            particularRole={rolesData}
            rolesFinalData={rolesApiData}
            show={open}
          />
        </List>
      </Drawer>

      <Outlet />
      <Popover
        id={roleId}
        open={popoverRoleOpen}
        anchorEl={roleAnchorE1}
        onClose={handleRoleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "start",
        }}
      >
        {Object.keys(rolesApiData).length > 0 &&
          rolesApiData?.getExcoCustomerUserRoles?.length > 0 &&
          rolesApiData?.getExcoCustomerUserRoles.map((data) => {
            return (
              <ListItem sx={{ width: "150px" }} disablePadding>
                <ListItemButton onClick={() => handleRoleChange(data.role)}>
                  <ListItemText primary={data.role} />
                </ListItemButton>
              </ListItem>
            );
          })}
      </Popover>

      <Popover
        id={id}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Link to="/exco/my-profile" className={classes.MuiLinks}>
          <ListItem disablePadding>
            <ListItemButton  onClick={() => handleClose()}>
              <ListItemIcon>
                <AssignmentIndIcon />
              </ListItemIcon>
              <ListItemText primary="My Profile" />
            </ListItemButton>
          </ListItem>
        </Link>
        <List>
          {/* <Link className={classes.MuiLinks}> */}
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleLogout()}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
          {/* </Link> */}
        </List>
      </Popover>
      {open ? (
        // <MuiTooltip title="Collapse">
        <Fab onClick={toggleDrawer} className={classes.MuiFabBtn}>
          <ChevronLeftIcon />
        </Fab>
      ) : (
        // </MuiTooltip>
        // <MuiTooltip title="Expand">
        <Fab onClick={toggleDrawer} className={classes.MuiFabBtn}>
          <ChevronRightIcon />
        </Fab>
        // </MuiTooltip>
      )}

      {message && <Notifier message={message} />}
      {errMessage && <Notifier message={errMessage} />}
    </Box>
  );
}

export default function Sidebar() {
  return <DashboardContent />;
}
