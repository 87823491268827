import React from "react";
// material ui
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "@mui/styles";



// -------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "none",
    marginTop: theme.spacing(2),
  },
}));





// ---------------------------------------------------------------

const FormSubmitButton = ({
  children,
  loading,
  type,
  variant,
  onClick,
  color,
  size,
  fullWidth,
  ...otherProps
}) => {
  const classes = useStyles();
  return (
    <LoadingButton
   
      type={type}
      variant={variant}
      onClick={onClick}
      color={color}
      
      size={size}
      fullWidth={fullWidth}
      loading={loading}
      className={classes.button}
      {...otherProps}
    >
      {children}
    </LoadingButton>
  );
};

export default FormSubmitButton;
