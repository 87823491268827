import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "./fonts/din/DIN Bold.ttf";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from "./app/store";
import { Provider } from "react-redux";
import { theme } from "./theme/Theme";
import { ThemeProvider } from "@mui/material";
// material ui


ReactDOM.render(
  <Provider store={store}>
   <ThemeProvider theme={theme}>
  <App/>
  </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

